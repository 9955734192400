import FootballPlayerWidgetModel from './football-player-widget.model';
import { FootballPlayerWidgetJson } from './football-player-widget.json';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import PlayerMatchModel from '../../../../../../../models/v2/player/match/player-match.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

export default class FootballPlayerWidgetBuilder {
	readonly json: FootballPlayerWidgetJson;

	constructor(model?: FootballPlayerWidgetModel | FootballPlayerWidgetJson) {
		if (model && model instanceof FootballPlayerWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as FootballPlayerWidgetJson;
		}
	}

	withPlayer = (player: PlayerModel): FootballPlayerWidgetBuilder => {
		this.json.player = player;

		return this;
	};

	withTournamentSeason = (tournamentSeason: EntitySeasonsSelectModel): FootballPlayerWidgetBuilder => {
		this.json.tournamentSeason = tournamentSeason;

		return this;
	};

	withPlayerTeams = (teams: string[]): FootballPlayerWidgetBuilder => {
		this.json.playerTeams = teams;

		return this;
	};

	withDisplayedMatch = (displayMatch: boolean): FootballPlayerWidgetBuilder => {
		this.json.displayMatch = displayMatch;

		return this;
	};

	withMatch = (match: PlayerMatchModel): FootballPlayerWidgetBuilder => {
		this.json.match = match;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): FootballPlayerWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers = (bookmakers: SportOddsBookmakerModel[] | null): FootballPlayerWidgetBuilder => {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withDisplayedStatsParameters = (displayStatsParameters: boolean): FootballPlayerWidgetBuilder => {
		this.json.displayStatsParameters = displayStatsParameters;

		return this;
	};

	withStatsParameters(statsParameters: string[]): FootballPlayerWidgetBuilder {
		this.json.statsParameters = statsParameters;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballPlayerWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballPlayerWidgetModel {
		return FootballPlayerWidgetModel.fromJson(this.json);
	}
}
