import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import CopyToProjectsHelper, { ProjectsWithCategories } from './CopyToProjectsHelper';
import { ContentTypes } from '../../../../constants/content-types';
import { actionService } from '../../../../App';
import { TOKEN_USER_ID } from '../../../../constants/constants';
import './CopyToProjectsModal.scss';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { store } from '../../../../store/store';
import { useTranslation } from 'react-i18next';

export const DISPLAY_MODAL = 'dispaly_modal';
export const HIDE_MODAL = 'hide_modal';

const emptyContentRequestData = { contentId: '', contentTitle: '', contentType: ContentTypes.ARTICLE };

export type SelectedProjectsState = {
	project: ProjectsWithCategories;
	categoryId: string;
	categoryTitle: string;
	categoryStatus: boolean;
	canCopy: boolean;
};

export const CopyToProjectsModal: FunctionComponent = () => {
	const [projects, setProjects] = useState([] as ProjectsWithCategories[]);
	const [selectedProjects, setSelectedProjects] = useState([] as SelectedProjectsState[]);
	const [selectedCategory, setSelectedCategory] = useState({ id: '', label: '' });
	const [selectedProject, setSelectedProject] = useState();
	const [contentRequestData, setContentRequestData] = useState(emptyContentRequestData);
	const [loading, setLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const helper = new CopyToProjectsHelper();
	const [t] = useTranslation();

	useEffect(() => {
		const subscription = actionService.onUIAction().subscribe((action: any) => {
			if (action.type === DISPLAY_MODAL) {
				setContentRequestData(action.data);
				setSelectedProjects([]);
				setProjects([]);
				setIsOpen(true);
				requestData();
			}

			if (action.type === HIDE_MODAL) {
				setContentRequestData(emptyContentRequestData);
				setIsOpen(false);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		requestData();
	}, [contentRequestData]);

	const requestData = () => {
		if (contentRequestData.contentId && contentRequestData.contentId.length > 0) {
			const currentProjectId = store.getState().project.currentProject.id;
			let userId = localStorage.getItem(TOKEN_USER_ID);
			setLoading(true);
			helper
				.getAvailableProjectsAndCategories(userId ? userId : '', currentProjectId)
				.then((resp) => {
					setProjects(resp);
					setLoading(false);
				})
				.catch((e) => {
					console.error(e);
					setLoading(false);
				});
		}
	};

	const addSelectedProject = (project: ProjectsWithCategories) => {
		const selectedProjectsCopy = selectedProjects.slice();
		const indexOfProject = selectedProjectsCopy.findIndex((selected) => selected.project.id === project.id);
		if (selectedProjectsCopy.length < 0) {
			selectedProjectsCopy.push({
				project: project,
				categoryId: selectedProjectsCopy[indexOfProject].categoryId.length === 0 ? '' : selectedProjectsCopy[indexOfProject].categoryId,
				categoryTitle:
					selectedProjectsCopy[indexOfProject].categoryTitle.length === 0 ? '' : selectedProjectsCopy[indexOfProject].categoryTitle,
				canCopy: selectedProjectsCopy[indexOfProject].categoryId.length !== 0,
				categoryStatus: '',
			});
			setSelectedProjects(selectedProjectsCopy);
			setSelectedProject(selectedProjectsCopy);
		} else {
			selectedProjectsCopy.push({
				project: project,
				categoryId: selectedCategory.id.length === 0 ? '' : selectedCategory.id,
				categoryTitle: selectedCategory.label.length === 0 ? '' : selectedCategory.label,
				canCopy: selectedCategory.id.length !== 0,
				categoryStatus: '',
			});

			setSelectedProjects(selectedProjectsCopy);
			setSelectedProject(selectedProjectsCopy);
		}
	};

	const removeSelectedProject = (project: ProjectsWithCategories) => {
		const selectedProjectsCopy = selectedProjects.filter((selected) => selected.project.id !== project.id);
		setSelectedProjects(selectedProjectsCopy);
	};

	const updateSelectedProjectWithCategory = (
		project: ProjectsWithCategories,
		categoryId: string,
		categoryTitle: string,
		categoryStatus?: boolean,
	) => {
		const selectedProjectsCopy = selectedProjects.slice();
		const indexOfProject = selectedProjectsCopy.findIndex((selected) => selected.project.id === project.id);
		if (indexOfProject > -1) {
			selectedProjectsCopy[indexOfProject].categoryId = categoryId;
			selectedProjectsCopy[indexOfProject].canCopy = true;
			selectedProjectsCopy[indexOfProject].categoryTitle = categoryTitle;
			selectedProjectsCopy[indexOfProject].categoryStatus = categoryStatus;
		}

		setSelectedCategory({
			id: selectedProjectsCopy[indexOfProject].categoryId,
			label: selectedProjectsCopy[indexOfProject].categoryTitle,
		});
		setSelectedProjects(selectedProjectsCopy);
	};

	const copyButtonDisabled = (
		projects: ProjectsWithCategories[],
		selectedProjects: SelectedProjectsState[],
		selectedProject: SelectedProjectsState,
	) => {
		let disabled = false;

		if (projects.length === 0) {
			disabled = true;
		} else if (selectedProjects.length === 0) {
			disabled = true;
		} else if (selectedProject && selectedProject[0] && selectedProject[0].canCopy === false) {
			disabled = true;
		} else if (selectedProjects.length > 0) {
			selectedProjects.forEach((project: SelectedProjectsState) => {
				if (project.categoryId.length === 0) {
					disabled = true;
				}
			});
		}

		return disabled;
	};

	return (
		<Modal
			className='copy-to-projects-modal'
			isOpen={isOpen}
			toggle={() => {
				setIsOpen(false);
			}}
		>
			<ModalHeader>
				{t('copy')}: {contentRequestData.contentTitle}
			</ModalHeader>
			<ModalBody>
				<div className={loading ? 'loading-overlay-h-auto' : ''}>
					{projects.length > 0 ? (
						<ProjectsTable
							projects={projects}
							t={t}
							helper={helper}
							selectedProjects={selectedProjects}
							addSelectedProject={addSelectedProject}
							removeSelectedProject={removeSelectedProject}
							updateSelectedProjectWithCategory={updateSelectedProjectWithCategory}
						/>
					) : (
						t('no_available_projects')
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color='danger'
					onClick={() => {
						setSelectedCategory({ id: '', label: '' });
						setIsOpen(false);
					}}
				>
					{t('close')}
				</Button>
				<Button
					disabled={copyButtonDisabled(projects, selectedProjects, selectedProject)}
					color='primary'
					onClick={() => {
						const projectsToCopyTo = selectedProjects.filter((selected) => selected.canCopy);

						if (projectsToCopyTo.length > 0) {
							new CopyToProjectsHelper()
								.postContentToProjects(contentRequestData.contentId, contentRequestData.contentType, projectsToCopyTo)
								.then(() => {
									toast.success(t('copy_project_success'));
									setSelectedCategory({ id: '', label: '' });
									actionService.emitUiAction({ type: HIDE_MODAL });
								});
						} else {
							toast.error(t('copy_project_error'));
						}
					}}
				>
					{t('copy')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const ProjectsTable: FunctionComponent<any> = ({
	projects,
	t,
	helper,
	selectedProjects,
	addSelectedProject,
	removeSelectedProject,
	updateSelectedProjectWithCategory,
}) => {
	const isProjectSelected = (project: ProjectsWithCategories) => {
		const selectedProjectIndex = selectedProjects.findIndex((selected: any) => selected.project.id === project.id);
		return selectedProjectIndex > -1;
	};

	return (
		<>
			<Row className='mb-2'>
				<Col md={1} xs={1}></Col>
				<Col md={4} xs={5}>
					{t('name')}
				</Col>
				<Col md={5} xs={5}>
					{t('category')}
				</Col>
				<Col md={1} xs={1}></Col>
			</Row>
			{projects.map((project: ProjectsWithCategories, index: number) => {
				return (
					<Row key={`key-${project.id}`} className='table-row pt-2 pb-2' id={project.id}>
						<Col md={1} xs={1} className='table-col pt-2 pb-2'>
							<input
								id={`checkbox-${project.id}`}
								type='checkbox'
								checked={isProjectSelected(project)}
								disabled={!helper.canCopyToProject(project.categories)}
								onChange={(evt) => {
									if (evt.target.checked) {
										addSelectedProject(project);
									} else {
										removeSelectedProject(project);
									}
								}}
							/>
						</Col>
						<Col md={4} xs={5}>
							<Label disabled={!helper.canCopyToProject(project.categories)} htmlFor={`checkbox-${project.id}`} className='table-col pt-2 pb-2'>
								{project.title}
							</Label>
						</Col>
						{helper.canCopyToProject(project.categories) ? (
							<Col md={5} xs={5}>
								<Select
									placeholder={t('select_category_placeholder')}
									options={helper.categoriesToOptions(project.categories)}
									isDisabled={!isProjectSelected(project)}
									onChange={(option: any) => {
										updateSelectedProjectWithCategory(project, option.value, option.label);
									}}
								/>
							</Col>
						) : (
							<Col md={5} xs={5} className='d-flex flex-row align-items-center'>
								{t('cant_copy_to_project')}
							</Col>
						)}
						<Col md={1} xs={1} className='d-flex flex-row align-items-center justify-content-center'>
							<i className={`${helper.projectCopyStateIcon(selectedProjects, project)} mx-2`} />
						</Col>
					</Row>
				);
			})}
		</>
	);
};
