import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

type Properties = {
	isChecked: boolean;
	onDisplayMainEventsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	blockId: string;
};

export const DisplayMainEvents: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();

	return (
		<Row className='mb-2'>
			<Col>
				<FormGroup key={`display-main-events-input-${props.blockId}`} check className='radio'>
					<Input
						className='form-check-input'
						type='checkbox'
						id={`display-main-events-input-${props.blockId}`}
						name='radios'
						onChange={props.onDisplayMainEventsChange}
						checked={props.isChecked || false}
						data-qa={`display-main-events-input-${props.blockId}`}
					/>
					<Label check htmlFor={`display-main-events-input-${props.blockId}`} className='form-check-label'>
						{t('display_main_events')}
					</Label>
				</FormGroup>
			</Col>
		</Row>
	);
};
