import React from 'react';
import SearchContentByType from './search-content-by-type';
import HttpService from '../../../../services/rest/HttpService';
import { Properties, State } from './properties/search-content-by-type-container.properties';
import {
	contentStatisticsToOptions,
	extractOptionIds,
	isFuOption,
	relatedTypesMap,
	responseToOptions,
} from './helpers/search-content-by-type-container.helper';
import { optionToSearchContentModel } from '../../Sidebar/tags/subcomponents/content-tags/helpers/content-tags-container.helper';
import { analyticsService, featuresService, multiLingualService } from '../../../../App';
import { DebounceInput } from 'react-debounce-input';
import ModelMapper from '../../../../models/ModelMapper';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import SuggestedListOptions from './suggested-list-options.container';
import SearchOptionsContainer from './search-options-container';
import Loader from 'react-spinners/BeatLoader';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../../../constants/content-types';
import { responseToPaginationModel } from '../../../../models/v2/Pagination/pagination.mapper';
import { Col, FormGroup, Label } from 'reactstrap';
import StatusSelect from '../../Sidebar/GeneralContentAttributes/subcomponents/status-select/status-select';
import { liveBlogStatusDropdownOptions } from '../../../Pages/Live Blog/components/subcomponents/status/status.helper';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import FuSuggestedListOptions from './components/fu-list-options';
import { articleOrigins, isExternalArticleSection, isExternalOriginAvailable } from '../../../Resources/Articles/Helpers/ArticleHelper';
import { store } from '../../../../store/store';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';

class SearchContentByTypeContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			searchType: 'articles',
			options: [],
			inputValue: '',
			isLoading: false,
			currentPage: 1,
			pagination: PaginationMeta.builder().build(),
			selectedStatus: '',
			sportType: {} as SportsTypesModel,
		};
	}

	private contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	private getRequestUrl = (mainString: string, searchText: string, liveblogs?: boolean) => {
		const searchUrl = new URLSearchParams('');

		if (searchText) searchUrl.append('query', searchText);

		if (!liveblogs && this.props.contentLanguage && multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages)) {
			searchUrl.append('language', this.props.contentLanguage);
		}

		if (this.state.selectedStatus !== '') {
			liveblogs ? searchUrl.append('Status', this.state.selectedStatus) : searchUrl.append('status', this.state.selectedStatus);
		}

		if (isExternalOriginAvailable() && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.RSM_ARTICLES)) {
			let externalOrigin;

			externalOrigin = store
				.getState()
				.origins.articleOrigins.find((articleOrigin: ContentAttributes) => articleOrigin.slug === articleOrigins.EXTERNAL);

			const externalFiltrationArticlesParameter = externalOrigin && externalOrigin.id ? externalOrigin.id : '';

			!featuresService.adminIsAuthorizedForExternalRSMArticlesFeature() &&
				searchUrl.append('exclude_origin_id', externalFiltrationArticlesParameter);
		}

		return `${mainString}&${searchUrl.toString()}`;
	};

	private requests = {
		articles: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/v2/articles/search?page=${page}`, search), null, header),
		videos: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/v2/videos/search?page=${page}`, search), null, header),
		galleries: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/v2/galleries/search?page=${page}`, search), null, header),
		live_blogs: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/liveblogs?page=${page}&title=${search}`, '', true), null, header),
	};

	onInputChange = (e: any) => {
		this.setState({ ...this.state, inputValue: e.target.value });
		this.requestContent(this.state.currentPage, e.target.value);
	};

	requestContent = async (page: number, search: string) => {
		const header = { Project: this.props.currentProject.domain };
		this.setState((state: State) => {
			return { ...state, isLoading: true };
		});

		if (search.length > 3 && !isFuOption(this.state.searchType)) {
			let pagination: any;
			let options: any[];

			await this.requests[this.state.searchType](page, search, header)
				.then((response: any) => {
					switch (this.state.searchType) {
						case `${ContentTypes.LIVE_BLOG}s`:
							pagination = responseToPaginationModel(response.data.meta);
							options = responseToOptions(response.data.data, relatedTypesMap[this.state.searchType]);
							this.setState((state: State) => {
								return { ...state, options, isLoading: false, currentPage: page, pagination };
							});
							break;
						default:
							pagination = ModelMapper.remapMetaPagination(response.data.meta.pagination);
							options = responseToOptions(response.data.data, relatedTypesMap[this.state.searchType]);
							this.setState(
								(state: State) => {
									return { ...state, options, isLoading: false, currentPage: page, pagination };
								},
								() => {
									if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) && this.props.listContent)
										this.getContentStatistics(options);
								},
							);
							break;
					}
				})
				.catch(() => this.resetState());
		} else this.resetState();
	};

	private getContentStatistics = (options: any) => {
		const { searchType } = this.state;
		const ids = extractOptionIds(options);

		HttpService.getContentStatistics(
			this.contentStatisticsConfig.request_headers[0],
			`${this.contentStatisticsConfig.url}?${searchType}=${ids}`,
		)
			.then((response: any) => {
				const optionsWithStatistics = contentStatisticsToOptions(options, response.data);
				this.setState((state: State) => {
					return { ...state, options: optionsWithStatistics };
				});
			})
			.catch((e: any) => e);
	};

	resetState = () => {
		this.setState((state: State) => {
			return { ...state, isLoading: false, options: [], currentPage: 1 };
		});
	};

	onStatusChange = (selectedStatus: string) => {
		this.setState({ ...this.state, selectedStatus }, () => {
			this.state.inputValue.length > 0 && this.requestContent(this.state.currentPage, this.state.inputValue);
		});
	};

	onSelect = (selection: string) => {
		this.setState({ ...this.state, searchType: selection, options: [], currentPage: 1, selectedStatus: '' }, () => {
			this.state.inputValue.length > 0 && this.requestContent(this.state.currentPage, this.state.inputValue);
		});
	};

	onRelatedContentSelect = (optionSelected: any, addItemToTop?: boolean) => {
		if (this.props.onRelatedContentSelect) {
			this.props.onRelatedContentSelect(optionToSearchContentModel(optionSelected), addItemToTop);
		}
	};

	onContentSelect = (optionSelected: any) => {
		if (this.props.onContentSelect) {
			this.props.onContentSelect(optionToSearchContentModel(optionSelected));
		}
		analyticsService.sendEvent('Added from search', 'Sidebar - Related content', optionSelected.type);
	};

	private getStatuses = () => {
		const { searchType } = this.state;
		const { statuses } = this.props;
		if (statuses) {
			switch (searchType) {
				case 'articles':
					return statuses[`articleStatuses`];
				case 'videos':
					return statuses[`videoStatuses`];
				case 'galleries':
					return statuses[`galleryStatuses`];
				case 'live_blogs':
					return liveBlogStatusDropdownOptions;
				default:
					return [];
			}
		}
	};

	render() {
		const { searchType, inputValue, isLoading, options, currentPage, pagination, selectedStatus } = this.state;
		const {
			list,
			listContent,
			statuses,
			t,
			currentProject,
			categoryIdFilter,
			toggleFirstLockPositionError,
			sportsFilter,
			showAddToTopToggle,
		} = this.props;

		return (
			<>
				<SearchContentByType type={searchType} onSearchTypeSelect={this.onSelect} />
				<div className='position-relative mb-3'>
					{!isFuOption(searchType) && (
						<>
							<Label className={'font-weight-bold'}>{t('available_content')}:</Label>
							<DebounceInput
								type='text'
								id={`search-content-by-type-${searchType}`}
								value={inputValue}
								onChange={this.onInputChange}
								debounceTimeout={500}
								className='form-control'
								placeholder={t('search')}
								required
							/>
						</>
					)}
					{isLoading && (
						<div className='position-absolute h-100 d-flex align-items-center opacity-03' style={{ top: '0', right: '10px' }}>
							<Loader size={5} />
						</div>
					)}
				</div>
				{statuses && this.getStatuses().length > 0 && !isFuOption(searchType) && (
					<>
						<Col col='6' sm='12' md='4'>
							<FormGroup>
								<Label htmlFor='date'>{t('select_status')}</Label>
								<StatusSelect
									isClearable
									contentType={searchType}
									selectedStatus={selectedStatus}
									statuses={this.getStatuses()}
									onStatusSelect={this.onStatusChange}
									t={t}
								/>
							</FormGroup>
						</Col>
						<SearchOptionsContainer
							listContent={listContent}
							listItems={listContent ? (list ? list.items : []) : list ? list : []}
							options={options}
							isLoading={isLoading}
							currentPage={currentPage}
							pagination={pagination}
							value={inputValue}
							t={t}
							requestContent={this.requestContent}
							onRelatedContentSelect={this.onRelatedContentSelect}
							list={list}
							toggleFirstLockPositionError={toggleFirstLockPositionError}
							showAddToTopToggle={showAddToTopToggle}
							type={searchType}
						/>
					</>
				)}
				{(() => {
					const components = {
						STANDARD_SUGGESTED_LIST_OPTIONS: (
							<SuggestedListOptions
								listItems={list.items ? list.items : []}
								list={list}
								t={t}
								categoryIdFilter={categoryIdFilter}
								sportsFilter={sportsFilter}
								onRelatedContentSelect={this.onRelatedContentSelect}
								domain={currentProject.domain}
								type={searchType}
								toggleFirstLockPositionError={toggleFirstLockPositionError}
								showAddToTopToggle={showAddToTopToggle}
								selectedStatus={selectedStatus}
							/>
						),
						FU_LIST_OPTIONS: (
							<FuSuggestedListOptions
								listItems={list.items ? list.items : []}
								list={list}
								onRelatedContentSelect={this.onRelatedContentSelect}
								type={searchType}
								toggleFirstLockPositionError={toggleFirstLockPositionError}
								showAddToTopToggle={showAddToTopToggle}
							/>
						),
					};

					switch (!isFuOption(searchType)) {
						case true:
							return listContent && components.STANDARD_SUGGESTED_LIST_OPTIONS;
						case false:
							return listContent && components.FU_LIST_OPTIONS;
						default:
							return null;
					}
				})()}
			</>
		);
	}
}

export default SearchContentByTypeContainer;
