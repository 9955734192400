import moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import SportOddsWidgetModel from '../models/sport-odds-widget.model';
import SportBookmakerModel from '../../../../../../../models/sport-odds/sport-bookmaker.model';

export type Properties = {
	blockPreview: SportOddsWidgetModel;
	t: any;
};

export const SportsOddsWidgetView: React.FunctionComponent<Properties> = ({ blockPreview, t }) => {
	if (blockPreview && blockPreview.game && blockPreview.game.id) {
		return (
			<div>
				<Row className='pt-2 pl-4 pr-4'>
					<Col className='text-left'>
						<Row className='mb-1'>
							<Col>
								<div className='mb-1 font-weight-bold d-inline-block mr-5'>
									{blockPreview.game.startTime ? moment(blockPreview.game.startTime).format('MMM Do YYYY, HH:mm') : ''}
								</div>
								<div className='d-inline-block'>
									<strong className='p-1'>{blockPreview.game.results[0] ? blockPreview.game.results[0].name : ''}</strong>
								</div>
								<div className='d-inline-block'>{t('vs')}</div>
								<div className='d-inline-block'>
									<strong className='p-1'>{blockPreview.game.results[1] ? blockPreview.game.results[1].name : ''}</strong>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr></hr>
							</Col>
						</Row>
						{blockPreview.bookmakers && blockPreview.bookmakers.length > 0 && (
							<Row className='mb-2'>
								<Col>
									{t('show')}:
									{blockPreview.bookmakers &&
										blockPreview.bookmakers.length > 0 &&
										blockPreview.bookmakers.map((bookmaker: SportBookmakerModel) => {
											return (
												<img
													key={bookmaker.id}
													height='20'
													alt={bookmaker.name}
													className='h-20 mx-1'
													src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
													title={bookmaker.name}
												/>
											);
										})}
								</Col>
							</Row>
						)}
						<Row>
							{blockPreview.market && blockPreview.market.name && blockPreview.market.name.length > 0 && (
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(blockPreview.market.name)}</strong>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</div>
		);
	}

	return null;
};
