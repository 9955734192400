import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Article from '../../models/article/Article';
import Related from '../../models/related/Related';
import List from '../../models/list/List';
import PaginationMeta from '../../models/pagination/PaginationMeta';

export const ARTICLE_LISTING_REQUEST = '[ACTION] ARTICLE_LISTING_REQUEST';
export const ARTICLE_LISTING_RECEIVED = '[ACTION] ARTICLE_LISTING_RECEIVED';
export const ARTICLE_LISTING_FAILED = '[ACTION] ARTICLE_LISTING_FAILED';

export const ARTICLE_ENTITY_RELATED_RECEIVED = '[ACTION] ARTICLE_ENTITY_RELATED_RECEIVED';
export const ARTICLE_COPY_RELATED_RECEIVED = '[ACTION] ARTICLE_COPY_RELATED_RECEIVED';
export const ARTICLE_ENTITY_RELATED_CREATE_SUCCESS = '[ACTION] ARTICLE_ENTITY_RELATED_CREATE_SUCCESS';
export const ARTICLE_ENTITY_RELATED_UPDATE_SUCCESS = '[ACTION] ARTICLE_ENTITY_RELATED_UPDATE_SUCCESS';

export const ARTICLE_ENTITY_REQUEST = '[ACTION] ARTICLE_ENTITY_REQUEST';
export const ARTICLE_ENTITY_RECEIVED = '[ACTION] ARTICLE_ENTITY_RECEIVED';

export const ARTICLE_SEARCH = '[ACTION] ARTICLE_SEARCH';
export const ARTICLE_SEARCH_FAILED = '[ACTION] ARTICLE_SEARCH_FAILED';

export const ARTICLE_ENTITY_CREATE = '[ACTION] ARTICLE_ENTITY_CREATE';
export const ARTICLE_ENTITY_CREATE_SUCCESS = '[ACTION] ARTICLE_ENTITY_CREATE_SUCCESS';
export const ARTICLE_ENTITY_CREATE_FAILED = '[ACTION] ARTICLE_ENTITY_CREATE_FAILED';

export const ARTICLE_ENTITY_UPDATE = '[ACTION] ARTICLE_ENTITY_UPDATE';
export const ARTICLE_ENTITY_UPDATE_FAILED = '[ACTION] ARTICLE_ENTITY_UPDATE_FAILED';
export const ARTICLE_ENTITY_UPDATE_SUCCESS = '[ACTION] ARTICLE_ENTITY_UPDATE_SUCCESS';

export const ARTICLE_ENTITY_DELETE = '[ACTION] ARTICLE_ENTITY_DELETE';
export const ARTICLE_ENTITY_DELETE_SUCCESS = '[ACTION] ARTICLE_ENTITY_DELETE_SUCCESS';
export const ARTICLE_ENTITY_DELETE_FAILED = '[ACTION] ARTICLE_ENTITY_DELETE_FAILED';

export const ARTICLE_CONTENT_STATISTICS_RECEIVED = '[ACTION] ARTICLE_CONTENT_STATISTICS_RECEIVED';
export const CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED = '[ACTION] CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED';

export const ARTICLE_PAGINATION_UPDATE = '[ACTION] ARTICLE_PAGINATION_UPDATE';

export const ARTICLE_FILTERS_UPDATE = '[ACTION] ARTICLE_FILTERS_UPDATE';

export const ARTICLE_ALREADY_EXISTS = '[ACTION] ARTICLE_ALREADY_EXISTS';

export const ARTICLE_CREATE_RESOURCES_SUCCESS = '[ACTION] ARTICLE_CREATE_RESOURCES_SUCCESS';

export const EXTERNAL_ARTICLES_FILTERS_UPDATE = '[ACTION] EXTERNAL_ARTICLES_FILTERS_UPDATE';
export const EXTERNAL_ARTICLE_PAGINATION_UPDATE = '[ACTION] EXTERNAL_ARTICLE_PAGINATION_UPDATE';
export const EXTERNAL_ARTICLE_LISTING_REQUEST = '[ACTION] EXTERNAL_ARTICLE_LISTING_REQUEST';
export const EXTERNAL_ARTICLE_LISTING_RECEIVED = '[ACTION] EXTERNAL_ARTICLE_LISTING_RECEIVED';
export const EXTERNAL_ARTICLE_SEARCH = '[ACTION] EXTERNAL_ARTICLE_SEARCH';

export function returnObjectForArticleListingRequest(page: string, project: Project, text: string) {
	return {
		type: ARTICLE_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForExternalArticleListingRequest(page: string, project: Project, text: string) {
	return {
		type: EXTERNAL_ARTICLE_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForArticlePaginationUpdate(pagination: PaginationMeta) {
	return {
		type: ARTICLE_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function returnObjectForExternalArticlePaginationUpdate(pagination: PaginationMeta) {
	return {
		type: EXTERNAL_ARTICLE_PAGINATION_UPDATE,
		payload: { externalArticlesPagination: pagination },
	};
}

export function returnObjectForArticleListingReceived(articles: any) {
	return {
		type: ARTICLE_LISTING_RECEIVED,
		payload: articles,
	};
}

export function returnObjectForExternalArticleListingReceived(articles: any) {
	return {
		type: EXTERNAL_ARTICLE_LISTING_RECEIVED,
		payload: articles,
	};
}

export function returnObjectForArticleListingFailed(error: any) {
	return {
		type: ARTICLE_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForArticleEntityCreate(article: Article, related: Related[], project: Project, list: null | List = null) {
	return {
		type: ARTICLE_ENTITY_CREATE,
		payload: { article, related, project, list },
	};
}

export function returnObjectForArticleLEntityCreateSuccess(id: string) {
	return {
		type: ARTICLE_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForArticleLEntityRelatedCreateSuccess(id: string) {
	return {
		type: ARTICLE_ENTITY_RELATED_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForArticleLEntityUpdate(article: Article, related: Related[], project: Project) {
	return {
		type: ARTICLE_ENTITY_UPDATE,
		payload: { article, related, project },
	};
}

export function returnObjectForArticleLEntityUpdateSuccess() {
	return {
		type: ARTICLE_ENTITY_UPDATE_SUCCESS,
	};
}
export function returnObjectForArticleLEntityUpdateFailed() {
	return {
		type: ARTICLE_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForArticleLEntityRelatedUpdateSuccess() {
	return {
		type: ARTICLE_ENTITY_RELATED_UPDATE_SUCCESS,
	};
}

export function returnObjectForArticleLEntityCreateFailed() {
	return {
		type: ARTICLE_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForArticleLEntityRequest(id: string, project: Project) {
	return {
		type: ARTICLE_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForArticleLEntityReceived(article: any) {
	return {
		type: ARTICLE_ENTITY_RECEIVED,
		payload: article,
	};
}

export function returnObjectForArticleLEntityRelatedReceived(related: any, sports: any) {
	return {
		type: ARTICLE_ENTITY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function articleCopyRelatedReceived(related: any, sports: any) {
	return {
		type: ARTICLE_COPY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function returnObjectForArticleSearch(text: string, project: Project) {
	return {
		type: ARTICLE_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForExternalArticleSearch(text: string, project: Project) {
	return {
		type: EXTERNAL_ARTICLE_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForArticleSearchFailed(error: any) {
	return {
		type: ARTICLE_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForArticleDelete(id: string, project: Project) {
	return {
		type: ARTICLE_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForArticleDeleteSuccess() {
	return {
		type: ARTICLE_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForArticleDeleteFailed() {
	return {
		type: ARTICLE_ENTITY_DELETE_FAILED,
	};
}

export function returnObjectForArticleContentStatisticsReceived(articles: Article[], contentStatistics: any) {
	return {
		type: ARTICLE_CONTENT_STATISTICS_RECEIVED,
		payload: { articles, contentStatistics },
	};
}

export function returnObjectForContentStatisticsArticleEntityReceived(article: Article, contentStatistics: any) {
	return {
		type: CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED,
		payload: { article, contentStatistics },
	};
}

export function returnObjectForArticleFiltersUpdate(filters: any) {
	return {
		type: ARTICLE_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForExternalArticlesFiltersUpdate(filters: any) {
	return {
		type: EXTERNAL_ARTICLES_FILTERS_UPDATE,
		payload: { externalArticlesFilters: filters },
	};
}

export function returnObjectForArticleAlreadyExists() {
	return {
		type: ARTICLE_ALREADY_EXISTS,
	};
}

export function triggerArticleCreateResourcesSuccess(id: number) {
	return {
		type: ARTICLE_CREATE_RESOURCES_SUCCESS,
		payload: id,
	};
}
