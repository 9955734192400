import { SET_EXTERNAL_ARTICLES_COUNT, SET_LAST_ACTIVITY_TIMESTAMP } from '../action-creators/AdminActivity';

const initialState = {
	lastActivityTimestamp: null,
	externalArticlesCount: null,
};

const externalArticlesReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_LAST_ACTIVITY_TIMESTAMP:
			return {
				...state,
				lastActivityTimestamp: action.payload,
			};
		case SET_EXTERNAL_ARTICLES_COUNT:
			return {
				...state,
				externalArticlesCount: action.payload,
			};
		default:
			return state;
	}
};

export default externalArticlesReducer;
