import i18n from '../i18n';

export const editorialTypesSelectOptions = [
	{
		label: i18n.t('type_of_automatic_items_options.option_1'),
		value: 'article',
	},
	{
		label: i18n.t('type_of_automatic_items_options.option_2'),
		value: 'gallery',
	},
	{
		label: i18n.t('type_of_automatic_items_options.option_3'),
		value: 'liveblog',
	},
	{
		label: i18n.t('type_of_automatic_items_options.option_4'),
		value: 'video',
	},
];

export const automaticContentSelectOptions = [
	{
		label: i18n.t('automatic_content_options.option_1'),
		value: 'most_recent',
	},
	{
		label: i18n.t('automatic_content_options.option_2'),
		value: 'most_popular',
	},
];

export const timeRangeSelectOptions = [
	{
		label: i18n.t('time_range_options.option_1'),
		value: '24 hour',
	},
	{
		label: i18n.t('time_range_options.option_2'),
		value: '7 day',
	},
	{
		label: i18n.t('time_range_options.option_3'),
		value: '14 day',
	},
	{
		label: i18n.t('time_range_options.option_4'),
		value: '21 day',
	},
	{
		label: i18n.t('time_range_options.option_5'),
		value: '30 day',
	},
];

export const frequencySelectOptions = [
	{
		label: i18n.t('update_frequency_options.option_1'),
		value: '1 hour',
	},
	{
		label: i18n.t('update_frequency_options.option_2'),
		value: '1 day',
	},
];
