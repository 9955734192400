import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { useTranslation } from 'react-i18next';
import { remapMatchesResponse, matchOption, matchesOptions } from '../helpers/match-select/player-match-select-component.helper';
import { AppState } from '../../../../../../store/store';
import PlayerMatchModel from '../../../../../../models/v2/player/match/player-match.model';
import { useSelector } from 'react-redux';
import { IMatchesResponse, ISelectOption } from '../../types/player';

interface Props {
	id: string;
	playerTeams: string[];
	selectedMatch: PlayerMatchModel;
	onMatchSelect: (match: PlayerMatchModel) => void;
}

const PlayerMatchesSelect: FC<Props> = (props) => {
	const [t] = useTranslation();
	const lang = useSelector((state: AppState) => state.project.currentProject.language);

	const { id, playerTeams, selectedMatch, onMatchSelect } = props;
	const [options, setOptions] = useState<ISelectOption<PlayerMatchModel>[]>([]);

	useEffect(() => {
		setOptions([]);
		if (playerTeams && playerTeams.length) {
			getMatchesByPlayer();
		}
	}, [playerTeams]);

	const updateMatchesOptionsState = (matches: ISelectOption<PlayerMatchModel>[]) => {
		if (!selectedMatch || !selectedMatch.id) {
			onMatchSelect(matches[0].data);
		}

		setOptions(matches);
	};

	const getMatchesByPlayer = () => {
		const ids = playerTeams.map((id) => id).join(',');
		HttpService.getMatchesByPlayerId(ids, lang)
			.then((response: IMatchesResponse) => {
				const matches = remapMatchesResponse(response.data.matches);
				const options = matchesOptions(matches);
				updateMatchesOptionsState(options);
			})
			.catch((e: any) => e);
	};

	const onChange = (option: ISelectOption<PlayerMatchModel> | null) => {
		if (option) {
			onMatchSelect(option.data);
			return;
		}
		onMatchSelect({} as PlayerMatchModel);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('match')}:</Label>
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					options={options}
					value={selectedMatch && selectedMatch.id ? matchOption(selectedMatch) : null}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={true}
					placeholder={t('select')}
					onChange={(selection) => onChange(selection as ISelectOption<PlayerMatchModel> | null)}
				/>
			</Col>
		</Row>
	);
};

export default PlayerMatchesSelect;
