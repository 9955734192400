import * as React from 'react';
import { useEffect, useState } from 'react';
import { featuresService, multiLingualService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import './ListingHeaders.scss';
import { AppState } from '../../../../../../store/store';
import { useSelector } from 'react-redux';
import Flag from 'react-world-flags';
import { isExternalArticleSection } from '../../../../../Resources/Articles/Helpers/ArticleHelper';

type ListingHeadersProps = {
	t: any;
	showLanguageFlag?: boolean;
};

export const HeadersArticle: React.FunctionComponent<ListingHeadersProps> = ({ t, showLanguageFlag }) => {
	const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);

	useEffect(() => {
		let languages = multiLingualService.extractAvailableLanguages(contentLanguages);
		if (multiLingualService.checkIfProjectIsMultiLingual(contentLanguages)) {
			setAvailableLanguages(languages);
		}
	}, []);

	return (
		<thead>
			<tr>
				<th className='w-title'>{t('title')}</th>
				{multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) &&
					showLanguageFlag &&
					contentLanguages &&
					availableLanguages &&
					availableLanguages.map((language: any) => {
						return (
							<th key={`table-header-flag-${language.languageCode}`} className='d-sm-table-cell align-middle text-center'>
								<Flag
									code={language.languageCode === 'en' ? 'gb' : language.languageCode}
									width='20'
									fallback={<i className='fa fa-flag ml-2' />}
								/>
							</th>
						);
					})}
				{isExternalArticleSection() && <th className=' w-origins d-none d-sm-table-cell'>{t('origin')}</th>}
				<th className='w-active d-none d-sm-table-cell'>{t('active')}</th>
				<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
				<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
				{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
					contentStatisticsConfig.mappings &&
					contentStatisticsConfig.mappings.length > 0 &&
					contentStatisticsConfig.mappings
						.filter((statistic: any) => statistic.is_main_statistic)
						.map((statistic: any) => {
							return (
								<th key={statistic.display_key} className='display-none d-sm-table-cell align-middle text-center'>
									{t(statistic.display_key)}
								</th>
							);
						})}
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersVideo: React.FunctionComponent<ListingHeadersProps> = ({ t, showLanguageFlag }) => {
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);

	useEffect(() => {
		let languages = multiLingualService.extractAvailableLanguages(contentLanguages);
		if (multiLingualService.checkIfProjectIsMultiLingual(contentLanguages)) {
			setAvailableLanguages(languages);
		}
	}, []);

	return (
		<thead>
			<tr>
				<th className='w-title'>{t('title')}</th>
				{multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) &&
					showLanguageFlag &&
					contentLanguages &&
					availableLanguages &&
					availableLanguages.map((language: any) => {
						return (
							<th key={`table-header-flag-${language.languageCode}`} className='d-sm-table-cell align-middle text-center'>
								<Flag
									code={language.languageCode === 'en' ? 'gb' : language.languageCode}
									width='20'
									fallback={<i className='fa fa-flag ml-2' />}
								/>
							</th>
						);
					})}
				<th className=''>{t('active')}</th>
				<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
				<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersGallery: React.FunctionComponent<ListingHeadersProps> = ({ t, showLanguageFlag }) => {
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);

	useEffect(() => {
		let languages = multiLingualService.extractAvailableLanguages(contentLanguages);
		if (multiLingualService.checkIfProjectIsMultiLingual(contentLanguages)) {
			setAvailableLanguages(languages);
		}
	}, []);

	return (
		<thead>
			<tr>
				<th className='w-title'>{t('title')}</th>
				{multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) &&
					showLanguageFlag &&
					contentLanguages &&
					availableLanguages &&
					availableLanguages.map((language: any) => {
						return (
							<th key={`table-header-flag-${language.languageCode}`} className='d-sm-table-cell align-middle text-center'>
								<Flag
									code={language.languageCode === 'en' ? 'gb' : language.languageCode}
									width='20'
									fallback={<i className='fa fa-flag ml-2' />}
								/>
							</th>
						);
					})}
				<th className=''>{t('active')}</th>
				<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
				<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersBanner: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersList: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead className='w-100'>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20' style={{ position: 'relative', right: '13%' }}>
					{t('active')}
				</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersAuthor: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('name')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersTag: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersCategory: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersPoll: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersFeed: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersTeam: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('team_name')}</th>
				<th className=''>{t('country')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('status')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersPlayer: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('player_name')}</th>
				<th className=''>{t('nationality')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('status')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersCoach: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('coach_name')}</th>
				<th className=''>{t('nationality')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('status')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};
