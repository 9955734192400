import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import TennisCompetitionSeasonModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisTournamentModel from '../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import SportsHttpService from '../../../../../../services/rest/sports-http-service';
import {
	responseToSeasonModel,
	seasonsToOptions,
	seasonToOption,
} from '../../../partials/season-select/helpers/tennis-season-select.helper';
import { responseToModelTennisTournament } from '../../../../../../models/v2/tennis-tournament/tennis-tournament-mapper';
import { customOption } from '../../../partials/shared/custom-select-option';
import ErrorHandler from '../../../partials/error/error-handler-component';
import CompetitionModel from '../../../../../../models/v2/competition/entity/competition.model';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	onSeasonAndTournamentsSelect: (season: TennisCompetitionSeasonModel, tournaments: TennisTournamentModel[]) => void;
	competition: CompetitionModel;
	selectedSeason: TennisCompetitionSeasonModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	sport: string;
	id: string;
	preselect: boolean;
};

export const TennisCompetitionSeasonSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [seasonsOptions, setSeasonsOptions] = useState<TennisCompetitionSeasonModel[]>([]);
	const [seasonTournaments, setSeasonTournaments] = useState<TennisTournamentModel[]>([]);
	const [preselectSeason, setPreselectSeason] = useState<boolean>(props.preselect);
	const [t] = useTranslation();

	useEffect(() => {
		if (props.competition && props.competition.id) {
			getSeasons(props.sport, props.competition.id, 'CURRENT', props.language);
			setPreselectSeason(true);
		}

		if (!props.competition) {
			setSeasonsOptions([]);
		}
	}, [props.competition && props.competition.id]);

	const updateSeasonsOptionsState = (
		seasons: TennisCompetitionSeasonModel[],
		tournaments: TennisTournamentModel[],
		selectedSeason?: TennisCompetitionSeasonModel,
	) => {
		setSeasonsOptions(seasons);
		if (
			!props.selectedSeason ||
			(props.selectedSeason && (props.selectedSeason.value === undefined || props.selectedSeason.value.length < 1) && preselectSeason)
		) {
			props.onSeasonAndTournamentsSelect(seasons[0], tournaments);
		} else {
			if (selectedSeason) {
				props.onSeasonAndTournamentsSelect(selectedSeason, tournaments);
			}
		}
	};

	const getSeasons = (sport: string, competitionId: string, season: string, lang: string, selectedSeason?: TennisCompetitionSeasonModel) => {
		SportsHttpService.getCompetitionSeasons(sport, competitionId, season, lang)
			.then((response: any) => {
				props.competition.currentSeasonYear = response.data.current_season_year;
				const tournaments = response.data.tournaments.map((item: TennisTournamentModel) => responseToModelTennisTournament(item));
				setSeasonTournaments(tournaments);
				if (tournaments && tournaments.length > 0) {
					updateSeasonsOptionsState(
						response.data.available_season_years.map((item: TennisCompetitionSeasonModel) => responseToSeasonModel(item)),
						tournaments,
						selectedSeason,
					);
				}
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-id={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('season')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={seasonsToOptions(seasonsOptions)}
					value={props.selectedSeason && props.selectedSeason.value ? seasonToOption(props.selectedSeason) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: SelectMenuOptionType) => {
						if (selection && selection.data !== null) {
							getSeasons(props.sport, props.competition.id, selection.data.value, props.language, selection.data);
						} else {
							props.onSeasonAndTournamentsSelect({} as TennisCompetitionSeasonModel, []);
						}
					}}
				/>
				{!props.isValid && !props.selectedSeason && <ErrorHandler t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};
