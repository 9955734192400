import React from 'react';
import './notifications-pill.styles.scss';

type StatusPillProps = {
	newItemsCount: number;
};

const NotificationsPill: React.FC<StatusPillProps> = ({ newItemsCount }) => {
	return (
		<div className='notifications-pill'>
			<span className='notifications-label'>{newItemsCount}</span>
		</div>
	);
};

export default NotificationsPill;
