import { put, takeEvery } from 'redux-saga/effects';
import { returnObjectForArticleFiltersUpdate, returnObjectForExternalArticlesFiltersUpdate } from '../action-creators/ArticleActionCreator';
import { UPDATE_CONTENT_FILTERS } from '../action-creators/content-resources';
import { returnObjectForGalleryFiltersUpdate } from '../action-creators/GalleryActionCreator';
import {
	returnObjectForImageFiltersUpdate,
	returnObjectForImagoImagesFiltersUpdate,
	returnObjectForPlayingSurfaceFiltersUpdate,
} from '../action-creators/ImageActionCreator';
import { returnObjectForJWVideoFiltersUpdate, returnObjectForVideoFiltersUpdate } from '../action-creators/VideoActionCreator';
import { returnObjectForWikiFiltersUpdate } from '../action-creators/WikiActionCreator';
import { returnObjectForLiveBlogsFiltersUpdate } from '../action-creators/LiveBlogsActionCreator';
function* updateContentFilters(action: any) {
	const filters = action.payload.filters;
	try {
		const articleFilters = filters.articleFilters;
		yield put(returnObjectForArticleFiltersUpdate(articleFilters));
	} catch (error) {}

	try {
		const externalArticlesFilters = filters.externalArticlesFilters;
		yield put(returnObjectForExternalArticlesFiltersUpdate(externalArticlesFilters));
	} catch (error) {}

	try {
		const jwVideosFilters = filters.jwFilters;
		yield put(returnObjectForJWVideoFiltersUpdate(jwVideosFilters));
	} catch (error) {}

	try {
		const videoFilters = filters.videoFilters;
		yield put(returnObjectForVideoFiltersUpdate(videoFilters));
	} catch (error) {}

	try {
		const galleryFilters = filters.galleryFilters;
		yield put(returnObjectForGalleryFiltersUpdate(galleryFilters));
	} catch (error) {}

	try {
		const imageFilters = filters.imageFilters;
		yield put(returnObjectForImageFiltersUpdate(imageFilters));
	} catch (error) {}

	try {
		const playingSurfaceFilters = filters.playingSurfaceFilters;
		yield put(returnObjectForPlayingSurfaceFiltersUpdate(playingSurfaceFilters));
	} catch (error) {}

	try {
		const imagoImageFilters = filters.imagoFilters;
		yield put(returnObjectForImagoImagesFiltersUpdate(imagoImageFilters));
	} catch (error) {}

	try {
		const wikiPagesFilters = filters.wikiPagesFilters;
		yield put(returnObjectForWikiFiltersUpdate(wikiPagesFilters));
	} catch (error) {}

	try {
		const LiveBlogsFilters = filters.liveBlogsFilters;
		yield put(returnObjectForLiveBlogsFiltersUpdate(LiveBlogsFilters));
	} catch (error) {}
}

function* contentResources() {
	yield takeEvery(UPDATE_CONTENT_FILTERS, updateContentFilters);
}

export default contentResources;
