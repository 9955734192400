import React, { FunctionComponent } from 'react';
import { Badge, Button } from 'reactstrap';
import Related from '../../../../../../models/related/Related';
import { Link } from 'react-router-dom';
import {
	autoGenerateReduxPropsNamingForContentModels,
	extractContentTypePluralNaming,
} from '../../../../../../services/content-models-service/ContentModelService';
import { EMBED_ICON } from '../../../Media/subcomponents/media-content/helpers/MainContentMediaContainer';
import moment from 'moment';
import { extractRelatedPropertiesNameByUrl } from '../../../tags-refactored/helpers/utils';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { generateRelatedUpdateActionByContentType } from '../../helpers/general.helper';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

type Properties = {
	t: any;
	relatedData: Related[];
	alreadyAddedRelatedContentRedux: Related[];
	contentModelId: string;
	updateRelatedContent: (related: Related[]) => void;
};

const RelatedContentList: FunctionComponent<Properties> = ({
	t,
	relatedData,
	alreadyAddedRelatedContentRedux,
	contentModelId,
	updateRelatedContent,
}) => {
	const onRelatedContentAdd = (relatedItem: Related) => updateRelatedContent([...alreadyAddedRelatedContentRedux, relatedItem]);
	const filteredRelatedData = relatedData.filter(
		(rel: Related) => rel.data.id !== contentModelId && !alreadyAddedRelatedContentRedux.find((el: Related) => el.data.id === rel.data.id),
	);

	if (
		relatedData.length < 1 ||
		(relatedData.length === 1 &&
			relatedData[0].data.id === contentModelId &&
			relatedData[0].data.entity_type === extractMainContentTypeBasedOnUrl())
	) {
		return <div className={'text-center text-muted p-3'}> {t('no_data_found')}</div>;
	}

	if (filteredRelatedData.length === 0) {
		return (
			<div className='text-center pt-2'>
				<strong>{t('no_more_content_to_select')}</strong>
			</div>
		);
	}

	return (
		<Scrollbars style={{ height: 300 }} className='top-shadow'>
			<ul className='list-group'>
				{filteredRelatedData.map((related: Related) => (
					<li
						key={`related-data-${related.data.id}`}
						id={`related-data-${related.data.id}`}
						className='list-group-item d-flex align-items-center'
					>
						<Button onClick={() => onRelatedContentAdd(related)} className='btn btn-light btn-sm mr-2'>
							<i className='fa fa-plus'></i>
						</Button>
						<Link to={`/smp/${extractContentTypePluralNaming(related.type)}/edit/${related.data.id}`} target='_blank' className='text-dark ml-1'>
							<i
								className={`option-logo mr-2 fa-lg fa ${
									related.data.status === 'active' ? 'text-success fa-check-circle' : 'text-danger fa-times-circle'
								}`}
							/>
							{related.data &&
								related.data.main_media &&
								related.data.main_media.map((media: any) => {
									return related.type === 'videos' && media.resource_type === 'embed' && EMBED_ICON;
								})}
							{related.data.title}
							<div>
								<Badge className='mt-1 text-dark published-date'>{moment(related.data.published_at).format('DD MMM YYYY, HH:mm')}</Badge>
							</div>
						</Link>
					</li>
				))}
			</ul>
		</Scrollbars>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		contentModelId: state[tempPropertyNaming][nestedTempPropertyNaming].id || '',
		alreadyAddedRelatedContentRedux: (nestedReduxPropertyName && state.tempContentRelated[nestedReduxPropertyName]) || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentType = extractMainContentTypeBasedOnUrl();
	const contentMode = extractContentModeBasedOnUrl();
	const generatedActionByContentType = generateRelatedUpdateActionByContentType(contentType, contentMode);

	return {
		updateRelatedContent: (related: Related[]) => generatedActionByContentType && dispatch(generatedActionByContentType(related)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedContentList);
