import React, { FunctionComponent } from 'react';
import Related from '../../../../../../models/related/Related';
import { SortableHandle } from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import moment from 'moment';
import { ContentTypes } from '../../../../../../constants/content-types';
import { EMBED_ICON } from '../../../Media/subcomponents/media-content/helpers/MainContentMediaContainer';

type Properties = {
	t: any;
	relatedItem: Related;
	onDelete: (itemType: string, itemId: string) => void;
};

const DragHandle = SortableHandle(() => (
	<span className='cursor-draggable'>
		<i className='fa fa-bars'></i>
	</span>
));

const contentTypeLogo = {
	article: <i className='fa fa-file-text-o'></i>,
	video: <i className='fa fa-play'></i>,
	gallery: <i className='fa fa-camera'></i>,
};

const RelatedContentSortableItem: FunctionComponent<Properties> = ({ t, relatedItem, onDelete }) => {
	if (!relatedItem) return null;

	return (
		<li
			id={`related-content-item-${relatedItem.type}-${relatedItem.data.id}`}
			key={`related-content-item-${relatedItem.type}-${relatedItem.data.id}`}
			className='list-group-item d-flex justify-content-between align-items-center'
		>
			<div className='d-flex justify-content-between align-items-center'>
				<div className='mr-3'>
					<DragHandle />
				</div>
				<div>
					<i
						className={`option-logo mr-2 fa-lg fa ${
							relatedItem.data.status === 'active' ? 'text-success fa-check-circle' : 'text-danger fa-times-circle'
						}`}
					/>
					{relatedItem.data &&
						relatedItem.data.main_media &&
						relatedItem.data.main_media.map((media: any) => {
							return relatedItem.type.toLocaleLowerCase() === ContentTypes.VIDEO && media.resource_type === 'embed' && EMBED_ICON;
						})}
					{contentTypeLogo[relatedItem.type]}
					<Link
						to={`/smp/${relatedItem.type.toLocaleLowerCase() === ContentTypes.GALLERY ? 'gallerie' : relatedItem.type.toLowerCase()}s/edit/${
							relatedItem.data.id
						}`}
						className='text-dark ml-1'
					>
						{relatedItem.data.title}
					</Link>
					<div>
						<Badge className='mt-1 text-dark search-options-published-date'>
							{moment(relatedItem.data.published_at).format('DD MMM YYYY, HH:mm')}
						</Badge>
					</div>
				</div>
			</div>
			<Button
				id={`remove-related-${relatedItem.type}-${relatedItem.data.id}`}
				onClick={() => onDelete(relatedItem.type, relatedItem.data.id)}
				className='btn btn-sm btn-danger mx-1'
			>
				<i className='fa fa-trash'></i> {t('remove')}
			</Button>
		</li>
	);
};

export default RelatedContentSortableItem;
