import { FootballPlayerWidgetJson } from './football-player-widget.json';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import FootballPlayerWidgetBuilder from './football-player-widget.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import PlayerMatchModel from '../../../../../../../models/v2/player/match/player-match.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

export default class FootballPlayerWidgetModel {
	readonly player: PlayerModel;
	readonly tournamentSeason: EntitySeasonsSelectModel;
	readonly playerTeams: string[];
	readonly displayMatch: boolean = true;
	readonly match: PlayerMatchModel;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly displayStatsParameters: boolean = true;
	readonly statsParameters: string[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		player: PlayerModel,
		tournamentSeason: EntitySeasonsSelectModel,
		playerTeams: string[],
		displayMatch: boolean,
		match: PlayerMatchModel,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		displayStatsParameters: boolean,
		statsParameters: string[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.player = player;
		this.tournamentSeason = tournamentSeason;
		this.playerTeams = playerTeams;
		this.displayMatch = displayMatch;
		this.match = match;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.displayStatsParameters = displayStatsParameters;
		this.statsParameters = statsParameters;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballPlayerWidgetJson {
		return {
			player: this.player,
			tournamentSeason: this.tournamentSeason,
			playerTeams: this.playerTeams,
			displayMatch: this.displayMatch,
			match: this.match,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			displayStatsParameters: this.displayStatsParameters,
			statsParameters: this.statsParameters,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballPlayerWidgetJson): FootballPlayerWidgetModel {
		return new FootballPlayerWidgetModel(
			json.player,
			json.tournamentSeason,
			json.playerTeams,
			json.displayMatch,
			json.match,
			json.displayOdds,
			json.bookmakers,
			json.displayStatsParameters,
			json.statsParameters,
			json.refreshTime,
		);
	}

	static builder(model?: FootballPlayerWidgetModel): FootballPlayerWidgetBuilder {
		return new FootballPlayerWidgetBuilder(model);
	}
}
