import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import RoundWidgetBuilder from './football-round-builder';
import { RoundWidgetJson } from './football-round.json';

export default class RoundWidgetModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: BasketballStageModel;
	readonly round: BasketballRoundModel;
	readonly dataDateFrom: string;
	readonly dataDateTo: string;
	readonly sortDirection: string;
	readonly limit: string;
	readonly refreshTime: RefreshTimeValuesType;
	readonly withRoundDropdown: boolean = false;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly displayHeader: boolean = false;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: BasketballStageModel,
		round: BasketballRoundModel,
		dataDateFrom: string,
		dataDateTo: string,
		sortDirection: string,
		limit: string,
		refreshTime: RefreshTimeValuesType,
		withRoundDropdown: boolean,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		displayHeader: boolean,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.round = round;
		this.dataDateFrom = dataDateFrom;
		this.dataDateTo = dataDateTo;
		this.sortDirection = sortDirection;
		this.limit = limit;
		this.refreshTime = refreshTime;
		this.withRoundDropdown = withRoundDropdown;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.displayHeader = displayHeader;
	}

	toJson(): RoundWidgetJson {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			round: this.round,
			dataDateFrom: this.dataDateFrom,
			dataDateTo: this.dataDateTo,
			sortDirection: this.sortDirection,
			limit: this.limit,
			refreshTime: this.refreshTime,
			withRoundDropdown: this.withRoundDropdown,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: RoundWidgetJson): RoundWidgetModel {
		return new RoundWidgetModel(
			json.tournament,
			json.season,
			json.stage,
			json.round,
			json.dataDateFrom,
			json.dataDateTo,
			json.sortDirection,
			json.limit,
			json.refreshTime,
			json.withRoundDropdown,
			json.displayOdds,
			json.bookmakers,
			json.displayHeader,
		);
	}

	static builder(model?: RoundWidgetModel): RoundWidgetBuilder {
		return new RoundWidgetBuilder(model);
	}
}
