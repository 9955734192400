import React, { FunctionComponent } from 'react';
import GeneralModal from '../../../../Partials/design-components/modal/modal';
import { connect } from 'react-redux';
import { ModalConfig } from '../../../../../store/reducers/multisport.reducer';
import { closeModal } from '../../../../../store/action-creators/multisport-action-creator';
import { MultisportModalsType } from './constants';
import MultisportModel from '../../models/sport.model';
import { convertPreviewDataForSave } from '../../helpers/utils';
import MultisportHttpService from '../../../../../services/rest/multisport-http-service';
import { toast } from 'react-toastify';
import i18n from '../../../../../i18n';

type Props = {
	modalSave: ModalConfig;
	clearSaveModal: () => void;
	previewData: MultisportModel[];
	configurationDate: Date;
	actionAfterCancel?: (changedConfigDate: Date, competitionList: string) => void;
	actionAfterSave?: (newData: MultisportModel[], competitionList: string) => void;
	configurationCompetitionList: string;
	configurationDateAsObj?: Date;
};

const generateBodyHTML = (isDateChanged: boolean = false) => {
	const resultBody = [<p key={2}>{i18n.t('multisport_save_modal_body')}</p>];
	if (isDateChanged) {
		return [<p key={1}>{i18n.t('multisport_save_before_date_change')}</p>, ...resultBody];
	}
	return resultBody;
};

export const SavePreviewEntities: FunctionComponent<Props> = ({
	modalSave,
	clearSaveModal,
	previewData,
	configurationDate,
	actionAfterCancel,
	actionAfterSave,
	configurationCompetitionList,
	configurationDateAsObj,
}) => {
	const { displayed, data } = modalSave;

	const effectiveConfigurationDate =
		configurationDate.getTime() !== ((configurationDateAsObj && configurationDateAsObj.getTime()) || 0)
			? configurationDateAsObj
			: configurationDate;

	const onClickAction = () => {
		const dataForSave = convertPreviewDataForSave(previewData);
		MultisportHttpService.savePreviewEvents(
			effectiveConfigurationDate ? effectiveConfigurationDate : configurationDate,
			dataForSave,
			configurationCompetitionList,
		)
			.then((response) => {
				if (response && response.status && response.status === 200) {
					toast.success(i18n.t('multisport_config_success_save'));
					actionAfterSave && actionAfterSave(structuredClone(previewData), configurationCompetitionList);
				}
			})
			.catch(() => toast.error(i18n.t('multisport_config_error_save')))
			.finally(() => (data && data.changedConfigDate && data.competitionList ? onClickClose() : clearSaveModal()));
	};

	const onClickClose = () => {
		if (actionAfterCancel && data) {
			try {
				const changedConfigDate = new Date(data.changedConfigDate) || new Date();
				const competitionList = data.competitionList;
				actionAfterCancel(changedConfigDate, competitionList);
			} catch (error) {
				toast.error(i18n.t('select_another_date'));
			}
		}
		clearSaveModal();
	};

	return (
		<GeneralModal
			isDisplayed={displayed}
			actionButtonText={i18n.t('save_changes')}
			isPositiveAction
			title={i18n.t('unsaved_changes_title')}
			bodyHTML={generateBodyHTML(data && data.changedConfigDate)}
			onClose={onClickClose}
			action={onClickAction}
		/>
	);
};

function mapStateToProps(state: any) {
	return {
		previewData: state.multisportConfiguration.previewData || [],
		modalSave: state.multisportConfiguration.modals.save || {},
		configurationDate: state.multisportConfiguration.configurationDate
			? new Date(state.multisportConfiguration.configurationDate)
			: new Date(),
		configurationCompetitionList: state.multisportConfiguration.configurationCompetitionList || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		clearSaveModal: () => dispatch(closeModal(MultisportModalsType.Save)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SavePreviewEntities);
