import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import FootballPlayerEditComponent from './football-player-edit.component';
import { FootballPlayerView } from './football-player-view.component';
import FootballPlayerWidgetModel from '../models/football-player-widget.model';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { BlockyV2Properties } from '../../../utils/helper';

const FootballPlayerWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <FootballPlayerEditComponent block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.FOOTBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballPlayerView
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel
						? block.data.preview.widgetModel
						: FootballPlayerWidgetModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballPlayerWidget);
