import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import RoundWidgetModel from './football-round.model';
import { RoundWidgetJson } from './football-round.json';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

export default class RoundWidgetBuilder {
	readonly json: RoundWidgetJson;

	constructor(model?: RoundWidgetModel | RoundWidgetJson) {
		if (model && model instanceof RoundWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as RoundWidgetJson;
		}
	}

	withTournament = (tournament: TournamentModel): RoundWidgetBuilder => {
		this.json.tournament = tournament;

		return this;
	};

	withSeason = (season: SeasonModel): RoundWidgetBuilder => {
		this.json.season = season;

		return this;
	};

	withStage = (stage: BasketballStageModel): RoundWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withRound = (rounds: BasketballRoundModel): RoundWidgetBuilder => {
		this.json.round = rounds;

		return this;
	};

	withDataDateFrom = (date: string): RoundWidgetBuilder => {
		this.json.dataDateFrom = date;

		return this;
	};

	withDataDateTo = (date: string): RoundWidgetBuilder => {
		this.json.dataDateTo = date;

		return this;
	};

	withSortDirection = (sortDirection: string) => {
		this.json.sortDirection = sortDirection;

		return this;
	};

	withLimit = (limit: string): RoundWidgetBuilder => {
		this.json.limit = limit;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): RoundWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): RoundWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): RoundWidgetBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): RoundWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRoundDropdown(withRoundDropdown: boolean): RoundWidgetBuilder {
		this.json.withRoundDropdown = withRoundDropdown;

		return this;
	}

	build(): RoundWidgetModel {
		return RoundWidgetModel.fromJson(this.json);
	}
}
