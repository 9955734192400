import Article from '../../models/article/Article';
import {
	ARTICLE_CONTENT_STATISTICS_RECEIVED,
	ARTICLE_ENTITY_DELETE_FAILED,
	ARTICLE_ENTITY_DELETE_SUCCESS,
	ARTICLE_FILTERS_UPDATE,
	ARTICLE_LISTING_RECEIVED,
	ARTICLE_PAGINATION_UPDATE,
	EXTERNAL_ARTICLE_LISTING_RECEIVED,
	EXTERNAL_ARTICLE_PAGINATION_UPDATE,
	EXTERNAL_ARTICLES_FILTERS_UPDATE,
} from '../action-creators/ArticleActionCreator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import AdvancedFilterModel from '../../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

interface InitialState {
	articles: Article[];
	pagination: PaginationMeta;
	externalArticlesPagination: PaginationMeta;
	articleDeleted: boolean;
	filters: AdvancedFilterModel;
	externalArticlesFilters: AdvancedFilterModel;
}

const initialState: InitialState = {
	articles: [],
	articleDeleted: false,
	pagination: PaginationMeta.builder().build(),
	externalArticlesPagination: PaginationMeta.builder().withCurrentPage(1).build(),
	filters: AdvancedFilterModel.builder().build(),
	externalArticlesFilters: AdvancedFilterModel.builder().build(),
};

function articleReducer(state: any = initialState, action: any) {
	if (action.type === ARTICLE_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			articles: action.payload.articles,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === EXTERNAL_ARTICLE_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			articles: action.payload.articles,
			externalArticlesPagination: action.payload.contentPagination,
		});
	}

	if (action.type === ARTICLE_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === EXTERNAL_ARTICLE_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			externalArticlesPagination: action.payload.externalArticlesPagination,
		});
	}

	if (action.type === ARTICLE_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			articleDeleted: true,
		});
	}

	if (action.type === ARTICLE_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			articleDeleted: false,
		});
	}

	if (action.type === ARTICLE_CONTENT_STATISTICS_RECEIVED) {
		return Object.assign({}, state, {
			articles: action.payload.articles,
		});
	}

	if (action.type === ARTICLE_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
		});
	}

	if (action.type === EXTERNAL_ARTICLES_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			externalArticlesFilters: action.payload.externalArticlesFilters,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default articleReducer;
