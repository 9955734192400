import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import {
	AppAside,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarMinimizer,
} from '@coreui/react';
// sidebar nav config
import { nav } from '../../routes/_nav';
// routes config
import routes from '../../routes/routes';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { logout } from '../../store/action-creators/ProfileActionCreators';
import BreadCrumbsCustom from '../../views/Partials/BaseComponents/BreadCrumbs/BreadCrumbsCustom';
import withGoogleAnalytics from '../../services/hoc/withGoogleAnalytics';
import SocialFeedContainer from '../../views/Partials/social-feed-selection-sidebar/social-feed-container';
import { NavBarWrapper } from '../../views/Partials/navbar/navbar.component';
import { FeatureTypes } from '../../services/feature-service/features.enum';
import { featuresService } from '../../App';
import { withRouter } from 'react-router';
import { setExternalArticlesCount, setLastActivityTimestamp } from '../../store/action-creators/AdminActivity';
import { fetchContentCount, getAdminActivityData } from '../../services/react-query/react-query.helper';

const DefaultAside = React.lazy(() => import('./DefaultAside/DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tooltipOpen: false,
		};
	}

	loading = () => <div className='animated fadeIn pt-1 text-center'>Loading...</div>;

	signOut(e) {
		e.preventDefault();
		this.props.logout();
		this.props.history.push('/login');
	}

	routeToLoginIfNotAuth() {
		if (this.props.auth && !this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
		}
	}

	componentDidMount() {
		this.props.analytics.init(this.props.currentProject.domain);

		// Initial data fetch
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.RSM_ARTICLES) &&
			featuresService.adminIsAuthorizedForExternalRSMArticlesFeature() &&
			this.fetchAdminActivity();

		// Set up manual polling every 5th minute
		this.activityInterval =
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.RSM_ARTICLES) &&
			featuresService.adminIsAuthorizedForExternalRSMArticlesFeature() &&
			setInterval(this.fetchAdminActivity, 300000);
	}

	componentWillUnmount() {
		if (this.activityInterval) {
			clearInterval(this.activityInterval);
		}
	}

	fetchAdminActivity = async () => {
		const { setLastActivityTimestamp } = this.props;

		try {
			const data = await getAdminActivityData();
			if (data && data.last_activity_timestamp) {
				setLastActivityTimestamp(data.last_activity_timestamp);
				this.fetchContentCount(data.last_activity_timestamp); // Trigger content count fetch after updating timestamp
			}
		} catch (error) {
			console.error('Failed to fetch admin activity:', error);
		}
	};

	fetchContentCount = async (lastActivityTimestamp) => {
		const { setExternalArticlesCount } = this.props;

		try {
			const data = await fetchContentCount('external', lastActivityTimestamp);
			setExternalArticlesCount(data.count);
		} catch (error) {
			console.error('Failed to fetch content count:', error);
		}
	};

	render() {
		const { t, loading, externalArticlesCount } = this.props;

		const navConfig = nav(t, externalArticlesCount);
		this.routeToLoginIfNotAuth();
		const socialFeedIsActive = featuresService.areFeaturesEnabled([FeatureTypes.NEWS_FEED]);

		return (
			<div className='app'>
				<AppHeader fixed>
					<Suspense fallback={this.loading()}>
						<DefaultHeader t={t} onLogout={(e) => this.signOut(e)} currentProject={this.props.currentProject} />
					</Suspense>
				</AppHeader>
				<div className='app-body'>
					<AppSidebar fixed display='lg' className={loading ? 'loading-overlay' : ''}>
						<AppSidebarHeader />
						<AppSidebarForm />
						<Suspense>
							<NavBarWrapper navConfig={navConfig} options={this.props} router={router} />
						</Suspense>
						<AppSidebarFooter />
						<AppSidebarMinimizer />
					</AppSidebar>
					{window.innerWidth > 1024 && socialFeedIsActive && (
						<SocialFeedContainer profile={this.props.profile} currentProject={this.props.currentProject} t={this.props.t} />
					)}
					<main className='main' style={socialFeedIsActive ? {} : { marginRight: '0px' }}>
						<BreadCrumbsCustom routes={routes(this.props.t)} location={this.props.location} />
						<Container fluid>
							<Suspense fallback={this.loading()}>
								<Switch>
									{routes(t)
										.filter((route) => route.enabled)
										.map((route, idx) => {
											return (
												<Route
													key={idx}
													path={route.path}
													exact={route.exact}
													name={route.name}
													render={(props) => <route.component {...this.props} />}
												/>
											);
										})}
									<Redirect from='*' to='/dashboard' />
								</Switch>
							</Suspense>
						</Container>
					</main>
					<AppAside fixed>
						<Suspense fallback={this.loading()}>
							<DefaultAside history={this.props.history} />
						</Suspense>
					</AppAside>
				</div>
				<AppFooter>
					<Suspense fallback={this.loading()}>
						<DefaultFooter />
					</Suspense>
				</AppFooter>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		profile: state.profile.profile,
		currentProject: state.project.currentProject,
		projects: state.project.projects,
		authors: state.author.authors,
		adminCategories: state.category.adminCategories,
		allCategories: state.category.allCategories,
		projectResourcesLoaded: state.project.projectResourcesLoaded,
		auth: state.auth,
		loading: state.general.loading,
		lastActivityTimestamp: state.adminActivity.lastActivityTimestamp,
		externalArticlesCount: state.adminActivity.externalArticlesCount,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		logout: () => dispatch(logout()),
		setLastActivityTimestamp: (timestamp) => dispatch(setLastActivityTimestamp(timestamp)),
		setExternalArticlesCount: (count) => dispatch(setExternalArticlesCount(count)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withGoogleAnalytics, withRouter)(DefaultLayout);
