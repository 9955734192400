import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generatePropsNamingByContentType,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
	isObjectEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import {
	contentAttributesToOptions,
	extractDefaultValue,
	extractSelectedStringAsContentAttribute,
} from '../../helpers/general-content-attributes.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	originsRedux: ContentAttribute[];
	selectedOrigin: ContentAttribute | null;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const OriginSelect: FunctionComponent<Properties> = ({ t, originsRedux, selectedOrigin, updateTempProperty }) => {
	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && !selectedOrigin) {
			const defaultOrigin = extractDefaultValue(originsRedux);
			defaultOrigin && defaultOrigin.slug && updateTempProperty({ [ReduxGeneralContentProps.ORIGIN]: defaultOrigin });
		}
	}, []);

	const onOriginSelect = (selectedTypeOption: SelectMenuOptionType) => {
		const selectedOrigin = (selectedTypeOption && selectedTypeOption.data) || {};
		updateTempProperty({ [ReduxGeneralContentProps.ORIGIN]: selectedOrigin });
	};

	const selectMenuValue = selectedOrigin ? extractSelectedStringAsContentAttribute(selectedOrigin.slug, originsRedux) : null;

	return isArrayEmpty(originsRedux) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.ORIGIN}>{t('origin')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.ORIGIN}
				value={selectMenuValue && contentAttributesToOptions([selectMenuValue])}
				options={contentAttributesToOptions(originsRedux)}
				onChange={onOriginSelect}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl() || '';
	const originsPropNaming = generatePropsNamingByContentType(contentTypeFromUrl) + 'Origins';
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	// We are doing this because if there are NOT selected comment policy the value is stored as empty object in Redux
	// So here we add logic to assign 'null' value if it's empty so we should check by null, not by empty object
	// If we change it directly in  redux many problems can occurs
	const selectedOrigin = state[tempPropertyNaming][nestedTempPropertyNaming].origin;

	return {
		selectedOrigin: !isObjectEmpty(selectedOrigin) ? selectedOrigin : null,
		originsRedux: state.origins[originsPropNaming],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginSelect);
