import React from 'react';
import './row-notification-pill.styles.scss';
import { useTranslation } from 'react-i18next';

type StatusPillProps = {
	label: string;
};

const RowNotificationsPill: React.FC<StatusPillProps> = ({ label }) => {
	const [t] = useTranslation();

	return (
		<div className='row-notifications-pill'>
			<span className='row-notifications-label'>{t(label)}</span>
		</div>
	);
};

export default RowNotificationsPill;
