import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import SortDirectionSelectComponent from '../../../partials/sort-direction-select/sort-direction-select.component';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES, WIDGET_ATTRIBUTE_NAMES } from '../../helpers/component-attributes.constants';
import {
	checkIfOddsAutoCheckEnabled,
	createBlock,
} from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import BasketballSeasonSelectComponent from '../../../../partials/season-select/basketball-season-select.component';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballStageSelectComponent from '../../../../partials/stage-select/basketball-stage-select.component';
import BasketballRoundSelectComponent from '../../../partials/round-select/basketball-round-select.component';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import BasketballTeamProgrammeWidgetModel from '../models/basketball-team-programme.model';
import SportsEntitySelect from '../../../../partials/sports-team-select/sports-entity-select.component';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import { ContentTypes } from '../../../../../../../constants/content-types';
import GenericSelect from '../../../../partials/generic-select/generic-select-component';
import { getDefaultType } from '../../../../blocks/content/embed-block/helpers/embed-block-edit.helper';
import { WidgetTeamProgrammeMatchTypeOptions } from '../../football-team/constants/football-team.constants';
import SortDirection from '../../../../../../../models/v2/sort-direction/sort-direction.model';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import LimitInput from '../../../../partials/limit-input/limit-input.component';
import { DateRangeInput } from '../../../partials/date-range-input/date-range-input.component';
import HeaderDisplayInput from '../../../partials/header-display-input/header-display-input.component';
import {
	FIXTURES_RESULTS_HEADER_DEFAULT_OPTIONS,
	onSelectionChangeFromMainComponent,
} from '../../../partials/header-default-option-input/helper';
import HeaderDefaultOptionWrapper from '../../../partials/header-default-option-input/header-default-option-wrapper';
import { DisplayOddsCheckbox } from '../../odds/components/display-odds-checkbox.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: BasketballTeamProgrammeWidgetModel;
	isValid: boolean;
};

export default class BasketballTeamProgrammeEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetBasketballTeamProgramme,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const teamProgrammeModel = BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: teamProgrammeModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onTeamSelect = (team: SportTeamModel) => {
		this.updateWidgetModelState(
			BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withTeam(team)
				.withSeason({} as BasketballSeasonModel)
				.withStage({} as BasketballStageModel)
				.withRounds([])
				.build(),
		);
	};

	onSeasonSelect = (season: BasketballSeasonModel) => {
		const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled() && !!(season && season.status && season.status === 'ACTIVE');

		this.updateWidgetModelState(
			BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as BasketballStageModel)
				.withRounds([])
				.withDisplayOdds(isAutoCheckEnabled)
				.build(),
		);
	};

	onStageSelect = (stage: BasketballStageModel) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withStage(stage).withRounds([]).build());
	};

	onRoundSelect = (rounds: BasketballRoundModel[]) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withRounds(rounds).build());
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onDataDateFromChange = (date: string) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withDataDateFrom(date).build());
	};

	onDataDateToChange = (date: string) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withDataDateTo(date).build());
	};

	onSortDirectionFixturesSelect = (sortDirection: SortDirection) => {
		this.updateWidgetModelState(
			BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSortDirectionFixtures(sortDirection && sortDirection.value)
				.build(),
		);
	};

	onSortDirectionResultsSelect = (sortDirection: SortDirection) => {
		this.updateWidgetModelState(
			BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSortDirectionResults(sortDirection && sortDirection.value)
				.build(),
		);
	};

	onDisplayOddsChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onMatchTypeChange = (matchType: string) => {
		this.updateWidgetModelState(BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withMatchType(matchType).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(
			refreshTimeData,
			BasketballTeamProgrammeWidgetModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	onHeaderDefaultOptionChange = (selectedHeaderDefaultOption: SelectMenuOptionType) => {
		onSelectionChangeFromMainComponent(
			selectedHeaderDefaultOption,
			BasketballTeamProgrammeWidgetModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	onDisplayHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(
			BasketballTeamProgrammeWidgetModel.builder(this.state.widgetModel).withDisplayHeader(e.target.checked).build(),
		);
	};

	updateWidgetModelState = (model: BasketballTeamProgrammeWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t, contentLanguage } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		return (
			<div data-qa='basketball-team-programme'>
				<Row>
					<Col>
						<FormGroup>
							<SportsEntitySelect
								inputId={`${SportTypes.BASKETBALL}-team-select`}
								isMulti={false}
								t={t}
								value={widgetModel.team ? widgetModel.team : null}
								onSelect={(team: SportTeamModel) => this.onTeamSelect(team)}
								isValid={isValid}
								sport={SportTypes.BASKETBALL}
								entityType={ContentTypes.TEAM}
								isClearable
								isRequired
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row data-qa='basketball-team-programme-season-select'>
					<Col>
						<FormGroup>
							<BasketballSeasonSelectComponent
								onSeasonSelect={this.onSeasonSelect}
								selectedSeason={
									widgetModel.season && widgetModel.season.id && widgetModel.season.id.length > 0
										? widgetModel.season
										: ({} as BasketballSeasonModel)
								}
								isValid={isValid}
								blockData={widgetModel}
								language={contentLanguage}
								isClearable
								isBlocky
								sport={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
								preselectSeason
								teamId={widgetModel.team && widgetModel.team.id !== '' ? widgetModel.team.id : ''}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col data-qa='basketball-team-programme-stage-select'>
						<FormGroup>
							<BasketballStageSelectComponent
								onStageSelect={this.onStageSelect}
								selectedStage={
									widgetModel.stage && widgetModel.stage.id && widgetModel.stage.id.length > 0 ? widgetModel.stage : ({} as BasketballStageModel)
								}
								isValid={isValid}
								blockData={widgetModel}
								language={contentLanguage}
								isClearable
								isBlocky
								sport={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col data-qa='basketball-team-programme-round-select'>
						<FormGroup>
							<BasketballRoundSelectComponent
								isMulti={true}
								isBlocky
								isValid={isValid}
								preselectRound={false}
								selectedRounds={widgetModel.rounds}
								stageRounds={widgetModel.stage ? widgetModel.stage.rounds : []}
								onRoundSelect={(round: BasketballRoundModel[]) => this.onRoundSelect(round)}
								isClearable
								isRequired={false}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.ROUND_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						<Label>
							<strong>{t('filters')}</strong>
						</Label>
					</Col>
				</Row>
				{widgetModel.team && widgetModel.team.id && (
					<Row className='mt-2' data-qa='basketball-team-programme-match-type-select'>
						<Col>
							<FormGroup>
								<Label htmlFor={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH_TYPE}`}>{t('match_type')}</Label>
								<GenericSelect
									t={t}
									isMulti={false}
									options={widgetModel.team && widgetModel.team.id && WidgetTeamProgrammeMatchTypeOptions}
									value={
										widgetModel.matchType ? widgetModel.matchType : this.onMatchTypeChange(getDefaultType(WidgetTeamProgrammeMatchTypeOptions, t))
									}
									onGenericOptionSelect={this.onMatchTypeChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<LimitInput onLimitChange={this.onLimitChange} value={widgetModel.limit} isValid={this.state.isValid} />
					</Col>
				</Row>
				<Row>
					<Col>
						<DateRangeInput
							date={widgetModel.dataDateFrom}
							onChange={this.onDataDateFromChange}
							id={WIDGET_ATTRIBUTE_NAMES.DATA_DATE_FROM}
							label={t('date_from')}
						/>
					</Col>
					<Col>
						<DateRangeInput
							date={widgetModel.dataDateTo}
							onChange={this.onDataDateToChange}
							id={WIDGET_ATTRIBUTE_NAMES.DATA_DATE_TO}
							label={t('date_to')}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SortDirectionSelectComponent
								selectedSortDirection={widgetModel.sortDirectionFixtures ? widgetModel.sortDirectionFixtures : ''}
								onSortDirectionSelect={this.onSortDirectionFixturesSelect}
								withLabel
								isClearable
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.SORT_DIRECTION_FIXTURES_SELECT}`}
								label={'sort_direction_fixtures'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<HeaderDisplayInput onDisplayHeaderChange={this.onDisplayHeaderChange} value={widgetModel.displayHeader} />
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SortDirectionSelectComponent
								selectedSortDirection={widgetModel.sortDirectionResults ? widgetModel.sortDirectionResults : ''}
								onSortDirectionSelect={this.onSortDirectionResultsSelect}
								withLabel
								isClearable
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.SORT_DIRECTION_RESULTS_SELECT}`}
								label={'sort_direction_results'}
							/>
						</FormGroup>
					</Col>
				</Row>
				{widgetModel.season && widgetModel.season.status && widgetModel.season.status === 'ACTIVE' && (
					<Row className='mb-2'>
						<Col>
							<DisplayOddsCheckbox
								blockId={this.props.block.id}
								onDisplayOddsChange={this.onDisplayOddsChange}
								isChecked={widgetModel.displayOdds}
								sport={SportTypes.BASKETBALL}
							/>
						</Col>
					</Row>
				)}
				{widgetModel && widgetModel.displayOdds && widgetModel.team && widgetModel.team.id && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isValid={this.state.isValid}
								showAllBookmakers={false}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={this.props.t}
								valueList={widgetModel.bookmakers ? widgetModel.bookmakers : []}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
							/>
						</Col>
					</Row>
				)}
				<HeaderDefaultOptionWrapper
					options={FIXTURES_RESULTS_HEADER_DEFAULT_OPTIONS}
					value={this.state.widgetModel.headerDefaultOption}
					onOptionChange={this.onHeaderDefaultOptionChange}
				/>
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
