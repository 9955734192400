import thunk from 'redux-thunk';
import { storeAuthToken } from './middleware/middleware';
import { PersistConfig } from 'redux-persist/es/types';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import createSagaMiddleware from '@redux-saga/core';
import loginSaga from './saga/loginSaga';
import projectSelectSaga from './saga/projectSelectSaga';
import pollingResources from './saga/polling-resources-saga';
import articleSaga from './saga/articleSaga';
import gallerySaga from './saga/gallerySaga';
import profileSaga from './saga/profileSaga';
import matchSaga from './saga/match-saga';
import videoSaga from './saga/videoSaga';
import { articleMiddleware } from './middleware/article.middleware';
import { galleryMiddleware } from './middleware/gallery.middleware';
import { videoMiddleware } from './middleware/video.middleware';
import { authorMiddleware } from './middleware/author.middleware';
import { categoryMiddleware } from './middleware/category.middleware';
import videoReducer from './reducers/video.reducer';
import articleReducer from './reducers/article.reducer';
import galleryReducer from './reducers/gallery.reducer';
import categoryReducer from './reducers/category.reducer';
import authorReducer from './reducers/author.reducer';
import profileReducer from './reducers/profile.reducer';
import projectReducer from './reducers/project.reducer';
import generalReducer from './reducers/general.reducer';
import authReducer from './reducers/auth.reducer';
import uiReducer from './reducers/ui.reducer';
import blockyReducer from './reducers/blocky.reducer';
import articleTempReducer from './reducers/article-temp.reducer';
import { typesMiddleware } from './middleware/types.middleware';
import typesReducer from './reducers/types.reducer';
import originsReducer from './reducers/origins.reducer';
import imageOwnersReducer from './reducers/image-owners.reducer';
import { imageOwnersMiddleware } from './middleware/image-owners.middleware';
import relatedSportsTempReducer from './reducers/related-sports-temp.reducer';
import relatedTagsTempReducer from './reducers/related-tags-temp.reducer';
import relatedContentTempReducer from './reducers/related-content-temp.reducer';
import relatedMatchTempReducer from './reducers/related-match-temp.reducer';
import imageSage from './saga/imageSaga';
import { imageMiddleware } from './middleware/image.middleware';
import videoTempReducer from './reducers/video-temp.reducer';
import { articleTempMiddleware } from './middleware/article-temp.middleware';
import galleryTempReducer from './reducers/gallery-temp.reducer';
import bannerReducer from './reducers/banner.reducer';
import bannerSaga from './saga/bannerSaga';
import { bannerMiddleware } from './middleware/banner.middleware';
import listReducer from './reducers/list.reducer';
import { listMiddleware } from './middleware/list.middleware';
import listSaga from './saga/listSaga';
import authorSaga from './saga/authorSaga';
import imageReducer from './reducers/image.reducer';
import { tagMiddleware } from './middleware/tag.middleware';
import tagSaga from './saga/tagSaga';
import tagReducer from './reducers/tag.reducer';
import categorySaga from './saga/categorySaga';
import pollSaga from './saga/poll-saga';
import { profileMiddleware } from './middleware/profile.middleware';
import pollReducer from './reducers/poll.reducer';
import { pollMiddleware } from './middleware/poll.middleware';
import { feedMiddleware } from './middleware/feed.middleware';
import feedSaga from './saga/feed-saga';
import feedReducer from './reducers/feed.reducer';
import statisticsReducer from './reducers/statistics.reducer';
import { statisticsMiddleware } from './middleware/statistics.middleware';
import statisticsSaga from './saga/statistics-saga';
import statusReducer from './reducers/status.reducer';
import { statusesMiddleware } from './middleware/statuses.middleware';
import { originsMiddleware } from './middleware/origins.middleware';
import commentsPoliciesReducer from './reducers/comments-policies.reducer';
import { commentsPoliciesMiddleware } from './middleware/comment-policies.middleware';
import teamReducer from './reducers/team.reducer';
import playerReducer from './reducers/player.reducer';
import { teamMiddleware } from './middleware/team.middleware';
import { playerMiddleware } from './middleware/player.middleware';
import teamSaga from './saga/team-saga';
import playerSaga from './saga/player-saga';
import matchLineupsReducer from './reducers/match-lineups-edit.reducer';
import matchLineupsCreateReducer from './reducers/match-lineups-create.reducer';
import { migrations } from './storeMigrations';
import contentResources from './saga/content-resources.saga';
import coachReducer from './reducers/coach.reducer';
import { coachMiddleware } from './middleware/coach.middleware';
import coachSaga from './saga/coach-saga';
import wikiPagesReducer from './reducers/wiki-pages.reducer';
import wikiTempReducer from './reducers/wiki-temp.reducer';
import { wikiMiddleware } from './middleware/wiki.middleware';
import wikiSaga from './saga/wikiSaga';
import { wikiTempMiddleware } from './middleware/wiki-temp.middleware';
import suggestedEntitiesReducer from './reducers/suggested-entities.reducer';
import { propertiesMiddleware } from './middleware/properties.middleware';
import propertiesReducer from './reducers/properties.reducer';
import sportsReducer from './reducers/sports.reducer';
import liveBlogsReducer from './reducers/live-blog.reducer';
import { liveBlogsMiddleware } from './middleware/live-blogs.middleware';
import liveBlogsSaga from './saga/live-blogs.saga';
import liveBlogEditorialReducer from './reducers/live-blog-editorial.reducer';
import LiveBlogModel from '../views/Pages/Live Blog/models/live-blog.model';
import multisportReducer from './reducers/multisport.reducer';
import customEntitiesReducer from './reducers/custom-entities.reducer';
import { CustomEntitiesSearchFiltersModel, Meta } from '../views/Pages/CustomEntities/models/models';
import adminActivityReducer from './reducers/admin-activity.reducer';

const persistConfig: PersistConfig = {
	key: 'root',
	storage,
	version: 22,
	migrate: createMigrate(migrations, { debug: true }),
};

export interface AppState {
	article: any;
	gallery: any;
	video: any;
	author: any;
	banner: any;
	tag: any;
	list: any;
	image: any;
	category: any;
	profile: any;
	project: any;
	general: any;
	auth: any;
	tempArticle: any;
	tempVideo: any;
	tempGallery: any;
	tempSportsRelated: any;
	tempTagsRelated: any;
	tempContentRelated: any;
	tempMatchRelated: any;
	types: any;
	imageOwners: any;
	ui: any;
	poll: any;
	feed: any;
	statistics: any;
	blockyReducer: any;
	statuses: any;
	commentsPolicies: any;
	team: any;
	player: any;
	matchLineupsEdit: any;
	matchLineupsCreate: any;
	coach: any;
	wikiPages: any;
	tempWiki: any;
	suggestedEntities: any;
	contentProperties: any;
	sports: any;
	liveBlogs: any;
	liveBlogEditorial: { configuration: LiveBlogModel | null };
	multisportConfiguration: any;
	customEntities: { pagination: Meta; filters: CustomEntitiesSearchFiltersModel; create: Record<string, any>; edit: Record<string, any> };
	adminActivity: { lastActivityTimestamp: string; externalArticlesCount: number };
}

const rootReducer = combineReducers({
	article: articleReducer,
	gallery: galleryReducer,
	video: videoReducer,
	author: authorReducer,
	banner: bannerReducer,
	tag: tagReducer,
	list: listReducer,
	image: imageReducer,
	category: categoryReducer,
	profile: profileReducer,
	project: projectReducer,
	general: generalReducer,
	auth: authReducer,
	tempArticle: articleTempReducer,
	tempVideo: videoTempReducer,
	tempGallery: galleryTempReducer,
	tempSportsRelated: relatedSportsTempReducer,
	tempTagsRelated: relatedTagsTempReducer,
	tempContentRelated: relatedContentTempReducer,
	tempMatchRelated: relatedMatchTempReducer,
	types: typesReducer,
	origins: originsReducer,
	imageOwners: imageOwnersReducer,
	ui: uiReducer,
	blocky: blockyReducer,
	poll: pollReducer,
	feed: feedReducer,
	statistics: statisticsReducer,
	statuses: statusReducer,
	commentsPolicies: commentsPoliciesReducer,
	team: teamReducer,
	player: playerReducer,
	matchLineupsEdit: matchLineupsReducer,
	matchLineupsCreate: matchLineupsCreateReducer,
	coach: coachReducer,
	wikiPages: wikiPagesReducer,
	tempWiki: wikiTempReducer,
	suggestedEntities: suggestedEntitiesReducer,
	contentProperties: propertiesReducer,
	sports: sportsReducer,
	liveBlogs: liveBlogsReducer,
	liveBlogEditorial: liveBlogEditorialReducer,
	multisportConfiguration: multisportReducer,
	customEntities: customEntitiesReducer,
	adminActivity: adminActivityReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
// @ts-ignore
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleWare = createSagaMiddleware();

const store = createStore(
	persistedReducer,
	storeEnhancers(
		applyMiddleware(
			storeAuthToken,
			videoMiddleware,
			galleryMiddleware,
			authorMiddleware,
			bannerMiddleware,
			tagMiddleware,
			listMiddleware,
			profileMiddleware,
			categoryMiddleware,
			articleMiddleware,
			articleTempMiddleware,
			typesMiddleware,
			originsMiddleware,
			imageOwnersMiddleware,
			imageMiddleware,
			pollMiddleware,
			thunk,
			sagaMiddleWare,
			feedMiddleware,
			statisticsMiddleware,
			statusesMiddleware,
			commentsPoliciesMiddleware,
			teamMiddleware,
			playerMiddleware,
			coachMiddleware,
			wikiMiddleware,
			wikiTempMiddleware,
			propertiesMiddleware,
			liveBlogsMiddleware,
		),
	),
);

const persistor = persistStore(store);

sagaMiddleWare.run(profileSaga);
sagaMiddleWare.run(loginSaga);
sagaMiddleWare.run(projectSelectSaga);
sagaMiddleWare.run(pollingResources);
sagaMiddleWare.run(videoSaga);
sagaMiddleWare.run(bannerSaga);
sagaMiddleWare.run(tagSaga);
sagaMiddleWare.run(categorySaga);
sagaMiddleWare.run(authorSaga);
sagaMiddleWare.run(listSaga);
sagaMiddleWare.run(articleSaga);
sagaMiddleWare.run(gallerySaga);
sagaMiddleWare.run(imageSage);
sagaMiddleWare.run(pollSaga);
sagaMiddleWare.run(feedSaga);
sagaMiddleWare.run(statisticsSaga);
sagaMiddleWare.run(teamSaga);
sagaMiddleWare.run(playerSaga);
sagaMiddleWare.run(matchSaga);
sagaMiddleWare.run(coachSaga);
sagaMiddleWare.run(contentResources);
sagaMiddleWare.run(wikiSaga);
sagaMiddleWare.run(liveBlogsSaga);

export { store, persistor };
