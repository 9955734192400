export const SET_LAST_ACTIVITY_TIMESTAMP = 'SET_LAST_ACTIVITY_TIMESTAMP';
export const SET_EXTERNAL_ARTICLES_COUNT = 'SET_EXTERNAL_ARTICLES_COUNT';

export const setLastActivityTimestamp = (timestamp: string) => {
	return {
		type: SET_LAST_ACTIVITY_TIMESTAMP,
		payload: timestamp,
	};
};

export const setExternalArticlesCount = (count: number) => {
	return {
		type: SET_EXTERNAL_ARTICLES_COUNT,
		payload: count,
	};
};
